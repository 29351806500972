var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('vue-alert',{staticClass:"mb-3 mt-2",attrs:{"primary":""}},[_vm._v(" Welcome to the Vibox Admin Panel ")]),_c('vue-data-table',{attrs:{"cols":[
      {
        header: 'Name',
        value: 'name',
        sort: true
      },
      {
        header: 'Email',
        value: 'email',
        sort: false
      },
      {
        header: 'Roles',
        value: 'roles',
        sort: false
      },
      {
        header: 'Created At',
        value: 'created_at',
        sort: false
      }
    ],"path":"users","request-params":{
      with: ['roles']
    }},scopedSlots:_vm._u([{key:"item.roles",fn:function(row){return [(row.roles && row.roles.length > 0)?_vm._l((row.roles),function(role){return _c('div',{key:role.name,staticClass:"w-full"},[_vm._v(" "+_vm._s(role.name)+" ")])}):_c('div',{staticClass:"w-full"},[_vm._v(" Customer ")])]}},{key:"item.created_at",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(row.created_at))+" ")]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"mb-4"},[_c('h1',{staticClass:"text-4xl"},[_vm._v(" Dashboard ")])])}]

export { render, staticRenderFns }