<template>
  <div>
    <header class="mb-4">
      <h1 class="text-4xl">
        Dashboard
      </h1>
    </header>
    <vue-alert primary class="mb-3 mt-2">
      Welcome to the Vibox Admin Panel
    </vue-alert>
    <vue-data-table
      :cols="[
        {
          header: 'Name',
          value: 'name',
          sort: true
        },
        {
          header: 'Email',
          value: 'email',
          sort: false
        },
        {
          header: 'Roles',
          value: 'roles',
          sort: false
        },
        {
          header: 'Created At',
          value: 'created_at',
          sort: false
        }
      ]"
      path="users"
      :request-params="{
        with: ['roles']
      }"
    >
      <template v-slot:item.roles="row">
        <template v-if="row.roles && row.roles.length > 0">
          <div v-for="role in row.roles" :key="role.name" class="w-full">
            {{ role.name }}
          </div>
        </template>
        <div v-else class="w-full">
          Customer
        </div>
      </template>
      <template v-slot:item.created_at="row">
        {{ $momentDateTimeHuman(row.created_at) }}
      </template>
    </vue-data-table>
  </div>
</template>

<script>
export default {
  name: 'Home',

  data () {
    return {
      dialog: false,
      error: {
        errors: {}
      },
      loading: false,
      form: {
        type: 'Room'
      }
    }
  },

  methods: {
    create () {
      if (this.$refs.form.validate() && !this.loading) {
        this.loading = true
      }
    }
  }
}
</script>
